<template>
    <v-container id="dashboard" fluid tag="section">
        <template>

            <v-layout row>

            <v-flex md-6>
                <Doughnut 
                    :chart-options="chartOptions"
                    :chart-data="chartData"
                    :chart-id="chartId"
                    :dataset-id-key="datasetIdKey"
                    :plugins="plugins"
                    :css-classes="cssClasses"
                    :styles="styles"
                    :width="width"
                    :height="height"
                />
            </v-flex>
            <v-flex md-6>
                <Bar
                    :chart-options="chartOptions"
                    :chart-data="chartData2"
                    :chart-id="chartId2"
                    :dataset-id-key="datasetIdKey"
                    :plugins="plugins"
                    :css-classes="cssClasses"
                    :styles="styles"
                    :width="width"
                    :height="height"
                />
            </v-flex>
                



            </v-layout>
            
        </template>

    </v-container>
</template>

<script>
    import { Bar, Doughnut } from 'vue-chartjs/legacy'
    import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, BarElement, CategoryScale, LinearScale } from 'chart.js'
    ChartJS.register(Title, Tooltip, Legend, BarElement, ArcElement, CategoryScale, LinearScale)

    import axios from "axios";
    export default {
        components: { Doughnut, Bar },
        props: {
            chartId: {
                type: String,
                default: 'doughnut-chart'
            },
            chartId2: {
                type: String,
                default: 'bar-chart'
            },
            cssClasses: {
                default: 'ma',
                type: String
            },
            width: {
                type: Number,
                default: 500
            },
            height: {
                type: Number,
                default: 500
            }
        },
        data () {
            return {
                govs: [],
                data: [],
                chartData: {
                    labels: [],
                    datasets: [ { 
                        label: '-',
                        borderWidth: 1,
                        data: [],
                        backgroundColor: [],
                    } ]
                },
                chartData2: {
                    labels: [],
                    datasets: [ { 
                        label: '-',
                        borderWidth: 1,
                        data: [],
                        backgroundColor: [],
                    } ]
                },
                chartOptions: {
                    responsive: false
                }
            }
        },
        created() {
            this.get_stats();
            this.get_stats2();
        },
        methods: {
            get_stats () {
                axios.get("jops/get_stats", {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                }
                }).then(res => {
                    res.data.data.forEach(element => {
                        this.chartData.labels.unshift(element.title)
                        this.chartData.datasets[0].data.unshift(element.count)
                        this.chartData.datasets[0].backgroundColor.unshift("#" + Math.floor(Math.random()*16777215).toString(16))
                    });
                })
            },

            get_stats2 () {
                axios.get("jops/get_stats2", {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                }
                }).then(res => {
                    res.data.data.forEach(element => {
                        this.chartData2.labels.unshift(element.title)
                        this.chartData2.datasets[0].data.unshift(element.count)
                        this.chartData2.datasets[0].backgroundColor.unshift("#" + Math.floor(Math.random()*16777215).toString(16))
                    });
                })
            },
        }
    }
</script>

<style scoped>
    .ma {
        display: flex;
        justify-content: center;
    }
    
</style>